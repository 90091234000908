import { Bookmark } from "src/codegen/bookmarks";
import { onSelector, querySelector } from "../helper/query-helper";

export class ResultPage {
  resultPage: HTMLElement;
  overviewPage: HTMLElement;
  main: HTMLElement;
  resultContent: HTMLDivElement;
  // input: HTMLInputElement;

  constructor() {
    this.resultPage = querySelector<HTMLElement>(document, "#result-page");
    this.overviewPage = querySelector<HTMLElement>(document, "#overview-page");
    this.main = querySelector<HTMLElement>(document, "main");
    this.resultContent = querySelector<HTMLDivElement>(
      this.resultPage,
      "#submission-info"
    );

    this.main.replaceChildren(this.overviewPage);

    this.onClickBack();
  }

  onResult(bookmark: Bookmark) {
    this.main.replaceChildren(this.resultPage);
    const imgHolder = querySelector<HTMLElement>(
      this.resultContent,
      "#submission-info-img"
    );
    const heading = querySelector<HTMLElement>(this.resultContent, "h3");
    const link = querySelector<HTMLAnchorElement>(this.resultContent, "a");

    if (bookmark.img !== "") {
      const img = document.createElement("img");
      img.src = bookmark.img;
      imgHolder.replaceChildren(img);
    } else {
      imgHolder.replaceChildren();
    }

    heading.textContent = bookmark.title;
    link.href = bookmark.url;
  }

  onClickBack() {
    onSelector("#return-button", "click", (e) => {
      e.preventDefault;
      this.main.replaceChildren(this.overviewPage);
    });
  }
}
