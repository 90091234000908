import { BookmarkList } from "./views/bookmark-list";
import { BookmarksDatabaseClient } from "./codegen/bookmarks";
import { LinkForm } from "./views/link-form";
import { v4 as uuidV4 } from "uuid";
import { ensureHttps, isValidUrl } from "./helper/url-validation";
import { fetchMetadata } from "./helper/fetch-url-metadata";
import { ITEMS_PER_PAGE, renderPagination } from "./views/pagination";
import { ResultPage } from "./views/result-page";

export class Controller {
  store: BookmarksDatabaseClient;
  bookmarkList: BookmarkList;
  linkForm: LinkForm;
  resultPage: ResultPage;
  currentPage: number;
  totalItems: number;

  constructor(
    store: BookmarksDatabaseClient,
    bookmarkList: BookmarkList,
    linkForm: LinkForm,
    resultPage: ResultPage
  ) {
    this.store = store;
    this.bookmarkList = bookmarkList;
    this.linkForm = linkForm;
    this.resultPage = resultPage;
    this.currentPage = 1;
    this.totalItems = 1;

    this.linkForm.onSubmit(this.handleAddNewBookmark.bind(this));
    this.bookmarkList.onDeleteItem(this.handleDeleteBookmark.bind(this));
    this.bookmarkList.onEditItem(this.handleEditBookmark.bind(this));
    this.bookmarkList.onSaveEditItem(this.handleSavedEditBookmark.bind(this));

    this.store.bookmarks.subscribe("add", (e) => {
      if (e.type === "add") {
        this.bookmarkList.addBookmark(e.data);
      }
    });
  }

  async show(page: number): Promise<void> {
    const bookmarks = await this.store.bookmarks.sortBy("id");
    this.totalItems = bookmarks.length <= 0 ? 1 : bookmarks.length;
    renderPagination(this.totalItems, page);

    const start = (page - 1) * ITEMS_PER_PAGE;
    const end = start + ITEMS_PER_PAGE;
    const itemsToShow = bookmarks.slice(start, end);

    this.bookmarkList.clear();
    for (const bookmark of itemsToShow) {
      this.bookmarkList.addBookmark(bookmark);
    }
  }

  async handleDeleteBookmark(id: string): Promise<void> {
    await this.store.bookmarks.delete({ id });
    this.bookmarkList.removeBookmark(id);
    if (this.bookmarkList.bookmarkList.childElementCount === 0) {
      this.show(this.currentPage - 1);
      return;
    }
    this.show(this.currentPage);
  }

  async handleEditBookmark(id: string): Promise<void> {
    const bookmark = await this.store.bookmarks.get({ id });
    this.bookmarkList.openEditInput(bookmark);
  }

  async handleSavedEditBookmark(editedBookmark: {
    bookmarkId: string;
    title: string;
    url: string;
    linkInput: HTMLInputElement;
    saveButton: HTMLButtonElement;
  }): Promise<void> {
    const bookmark = await this.store.bookmarks.get({
      id: editedBookmark.bookmarkId,
    });

    if (!isValidUrl(editedBookmark.url)) {
      editedBookmark.linkInput.classList.add("invalid");
      editedBookmark.linkInput.focus();
      return;
    }

    editedBookmark.saveButton.disabled = true;
    editedBookmark.linkInput.disabled = true;
    const metaData = await fetchMetadata(editedBookmark.url);
    editedBookmark.saveButton.disabled = false;
    editedBookmark.linkInput.disabled = false;

    if (metaData.error || typeof metaData.metadata === "undefined") {
      editedBookmark.linkInput.classList.add("invalid");
      return;
    }

    editedBookmark.linkInput.classList.remove("invalid");

    await this.store.bookmarks.put({
      id: editedBookmark.bookmarkId,
      url:
        typeof editedBookmark.url === "undefined"
          ? bookmark.url
          : ensureHttps(editedBookmark.url),
      title:
        typeof editedBookmark.title === "undefined"
          ? bookmark.title
          : editedBookmark.title,
      img: bookmark.img,
      tag: bookmark.tag,
      createdAt: bookmark.createdAt,
    });

    this.bookmarkList.openEditInput(bookmark);
    this.show(this.currentPage);
  }

  async handleAddNewBookmark(url: string): Promise<void> {
    this.linkForm.form.classList.remove("invalid");
    this.linkForm.addErrorMessage("");

    if (!isValidUrl(url)) {
      this.linkForm.form.classList.add("invalid");
      this.linkForm.input.focus();
      this.linkForm.addErrorMessage("Invalid URL format.");

      return;
    }

    this.linkForm.button.disabled = true;
    this.linkForm.input.disabled = true;
    this.linkForm.input.value = " Loading...";

    const metaData = await fetchMetadata(url);

    this.linkForm.button.disabled = false;
    this.linkForm.input.disabled = false;
    this.linkForm.input.value = "";

    if (metaData.error || typeof metaData.metadata === "undefined") {
      this.linkForm.form.classList.add("invalid");
      this.linkForm.input.focus();
      this.linkForm.addErrorMessage(metaData.error || "Error fetch data");

      return;
    }

    try {
      const bookmark = {
        id: uuidV4(),
        url: ensureHttps(metaData.metadata.url),
        title: metaData.metadata.title,
        img: metaData.metadata.image || "",
        tag: "",
        createdAt: new Date(),
      };

      await this.store.bookmarks.add(bookmark);
      await this.resultPage.onResult(bookmark);
    } catch (error) {}

    this.linkForm.clearInput();
  }
}
