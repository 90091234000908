import { querySelector } from "../../src/helper/query-helper";

export const ITEMS_PER_PAGE = 5;

export function renderPagination(totalItems: number, currentPage: number) {
  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
  const pagination1 = querySelector<HTMLDivElement>(document, "#pagination1");
  const pagination2 = querySelector<HTMLDivElement>(document, "#pagination2");

  let buttons = '<span class="page-link" onclick="prevPage()"> < </span>';

  for (let i = 1; i <= totalPages; i++) {
    buttons += `<span class="page-link ${
      i === currentPage ? "active" : ""
    }" onclick="changePage(${i})">${i}</span>`;
  }

  buttons += '<span class="page-link" onclick="nextPage()" > > </span>';
  pagination1.innerHTML = buttons;
  pagination2.innerHTML = buttons;
}
