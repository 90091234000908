/* eslint-disable */
/*
 * Autogenerated by indeksd v0.0.17
 * DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
 */
export type Bookmark = {
  id: string;
  url: string;
  title: string;
  img: string;
  tag: string;
  createdAt: Date;
};
export type BookmarksIndexes = "id";
export type BookmarksWhereQueryType<IndexName extends BookmarksIndexes> =
  IndexName extends "id" ? string : never;
export type BookmarksAddArgs = {
  id: string;
  url: string;
  title: string;
  img: string;
  tag: string;
  createdAt: Date;
};
export type BookmarksPutArgs =
  | BookmarksAddArgs
  | {
      id: string;
      url: string;
      title: string;
      img: string;
      tag: string;
      createdAt: Date;
    };
export type BookmarksDeleteArgs =
  | string
  | {
      id: string;
    };
export type BookmarksGetArgs =
  | string
  | {
      id: string;
    };
const isBookmarksIdIndex = (
  arg?: BookmarksGetArgs
): arg is {
  id: string;
} => {
  return (
    typeof arg === "object" &&
    Object.keys(arg).length === 1 &&
    Reflect.has(arg, "id")
  );
};
type RangeQuery<ArgType, ReturnType> = {
  isGreaterThan(arg: ArgType): ReturnType;
  isGreaterThanOrEqualTo(arg: ArgType): ReturnType;
  isLessThan(arg: ArgType): ReturnType;
  isLessThanOrEqualTo(arg: ArgType): ReturnType;
  isBetween(arg: { from: ArgType; to: ArgType }): ReturnType;
  isEqualTo(arg: ArgType): ReturnType;
};
export type SubscriptionEvent<ItemType, PrimaryKeyType> =
  | {
      type: "add" | "put";
      data: ItemType;
    }
  | {
      type: "delete";
      data: PrimaryKeyType;
    };
export type BookmarksClient = {
  subscribe(
    eventName: "change" | "add" | "put" | "delete",
    callback: (event: SubscriptionEvent<Bookmark, string>) => void
  ): void;
  add(
    arg: BookmarksAddArgs,
    options?: {
      transaction?: IDBTransaction;
    }
  ): Promise<Bookmark>;
  put(
    arg: BookmarksPutArgs,
    options?: {
      transaction?: IDBTransaction;
    }
  ): Promise<Bookmark>;
  delete(
    arg: BookmarksDeleteArgs,
    options?: {
      transaction?: IDBTransaction;
    }
  ): Promise<void>;
  get(
    arg: BookmarksGetArgs,
    options?: {
      transaction?: IDBTransaction;
    }
  ): Promise<Bookmark>;
  where<IndexName extends BookmarksIndexes>(
    indexName: IndexName,
    options?: {
      transaction?: IDBTransaction;
    }
  ): RangeQuery<
    BookmarksWhereQueryType<IndexName>,
    Promise<ReadonlyArray<Bookmark>>
  >;
  sortBy(
    indexName: BookmarksIndexes,
    options?: {
      transaction?: IDBTransaction;
      count?: number;
    }
  ): Promise<ReadonlyArray<Bookmark>>;
};
export type BookmarksDatabaseClient = {
  transaction: (
    storeNames: Array<"Bookmarks">,
    mode?: IDBTransactionMode
  ) => IDBTransaction;
  bookmarks: BookmarksClient;
};
function createDatabaseClient(db: IDBDatabase): BookmarksDatabaseClient {
  class Observable<ItemType, PrimaryKeyType> {
    #listeners: Map<
      string,
      Map<number, (event: SubscriptionEvent<ItemType, PrimaryKeyType>) => void>
    > = new Map();
    #nextId: number = 0;
    subscribe(
      eventName: "change" | "add" | "put" | "delete",
      callback: (event: SubscriptionEvent<ItemType, PrimaryKeyType>) => void
    ): void {
      if (this.#listeners.has(eventName)) {
        this.#listeners.get(eventName)?.set(this.#nextId, callback);
      } else {
        const innerMap: Map<
          number,
          (event: SubscriptionEvent<ItemType, PrimaryKeyType>) => void
        > = new Map();
        innerMap.set(this.#nextId, callback);
        this.#listeners.set(eventName, innerMap);
      }
      this.#nextId += 1;
    }
    _push(eventName: "delete", data: PrimaryKeyType): void;
    _push(eventName: "add" | "put", data: ItemType): void;
    _push(
      eventName: "add" | "put" | "delete",
      data: ItemType | PrimaryKeyType
    ): void {
      this.#listeners.get("change")?.forEach((callback) => {
        callback({ type: eventName, data } as SubscriptionEvent<
          ItemType,
          PrimaryKeyType
        >);
      });
      this.#listeners.get(eventName)?.forEach((callback) => {
        callback({ type: eventName, data } as SubscriptionEvent<
          ItemType,
          PrimaryKeyType
        >);
      });
    }
  }
  class BookmarksClientImpl extends Observable<Bookmark, string> {
    static tablesForTransaction: ReadonlyArray<string> = ["Bookmarks"];
    add(
      arg: BookmarksAddArgs,
      options?: {
        transaction?: IDBTransaction;
      }
    ): Promise<Bookmark> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(["Bookmarks"], "readwrite");
        const store = tx.objectStore("Bookmarks");
        const [] = await Promise.all([]);
        const DBAddRequest: IDBRequest = store.add({
          ...arg,
        });
        DBAddRequest.onerror = () => {
          if (DBAddRequest != null) {
            reject(DBAddRequest.error);
          } else {
            reject(
              new Error("Unknown error occurred trying to perform operation")
            );
          }
        };
        DBAddRequest.onsuccess = () => {
          if (DBAddRequest != null) {
            const mergedResult: Bookmark = {
              ...arg,
              id: DBAddRequest.result,
            } as Bookmark;
            this._push("add", mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error("Operation produced a null result"));
          }
        };
      });
    }
    put(
      arg: BookmarksPutArgs,
      options?: {
        transaction?: IDBTransaction;
      }
    ): Promise<Bookmark> {
      return new Promise(async (resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(["Bookmarks"], "readwrite");
        const store = tx.objectStore("Bookmarks");
        const [] = await Promise.all([]);
        const DBPutRequest: IDBRequest = store.put({
          ...arg,
        });
        DBPutRequest.onerror = () => {
          if (DBPutRequest != null) {
            reject(DBPutRequest.error);
          } else {
            reject(
              new Error("Unknown error occurred trying to perform operation")
            );
          }
        };
        DBPutRequest.onsuccess = () => {
          if (DBPutRequest != null) {
            const mergedResult: Bookmark = {
              ...arg,
              id: DBPutRequest.result,
            } as Bookmark;
            this._push("put", mergedResult);
            resolve(mergedResult);
          } else {
            reject(new Error("Operation produced a null result"));
          }
        };
      });
    }
    delete(
      arg: BookmarksDeleteArgs,
      options?: {
        transaction?: IDBTransaction;
      }
    ): Promise<void> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(["Bookmarks"], "readwrite");
        const store = tx.objectStore("Bookmarks");
        const idToDelete: string = isBookmarksIdIndex(arg) ? arg.id : arg;
        const DBDeleteRequest: IDBRequest = store.delete(idToDelete);
        if (DBDeleteRequest != null) {
          DBDeleteRequest.onerror = () => {
            if (DBDeleteRequest != null) {
              reject(DBDeleteRequest.error);
            } else {
              reject(
                new Error("Unknown error occurred trying to perform operation")
              );
            }
          };
          DBDeleteRequest.onsuccess = () => {
            this._push("delete", idToDelete);
            resolve(undefined);
          };
        } else {
          reject(new Error("No available index for given query"));
        }
      });
    }
    get(
      arg: BookmarksGetArgs,
      options?: {
        transaction?: IDBTransaction;
      }
    ): Promise<Bookmark> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(["Bookmarks"], "readonly");
        const store = tx.objectStore("Bookmarks");
        let DBGetRequest: IDBRequest | null = null;
        if (isBookmarksIdIndex(arg)) {
          DBGetRequest = store.get(arg.id);
        } else {
          DBGetRequest = store.get(arg);
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error("Unknown error occurred trying to perform operation")
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error("No result found for query"));
            }
          };
        } else {
          reject(new Error("No available index for given query"));
        }
      });
    }
    where<IndexName extends BookmarksIndexes>(
      indexName: IndexName,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
      }
    ): RangeQuery<
      BookmarksWhereQueryType<IndexName>,
      Promise<ReadonlyArray<Bookmark>>
    > {
      const tx =
        options?.transaction ?? db.transaction(["Bookmarks"], "readonly");
      const store = tx.objectStore("Bookmarks");
      const executeQuery = (
        target: IDBObjectStore | IDBIndex,
        range: IDBKeyRange
      ) => {
        return new Promise<ReadonlyArray<Bookmark>>(async (resolve, reject) => {
          const DBGetRequest: IDBRequest = target.getAll(range);
          if (DBGetRequest != null) {
            DBGetRequest.onerror = () => {
              if (DBGetRequest != null) {
                reject(DBGetRequest.error);
              } else {
                reject(
                  new Error(
                    "Unknown error occurred trying to perform operation"
                  )
                );
              }
            };
            DBGetRequest.onsuccess = () => {
              if (DBGetRequest != null && DBGetRequest.result != null) {
                resolve((DBGetRequest as IDBRequest).result);
              } else {
                reject(new Error("No result found for query"));
              }
            };
          } else {
            reject(new Error("No available index for given query"));
          }
        });
      };
      return {
        isGreaterThan(query) {
          switch (indexName) {
            case "id": {
              return executeQuery(store, IDBKeyRange.lowerBound(query, true));
            }
            default: {
              return Promise.reject(
                new Error("Trying to run query on unknown index: " + indexName)
              );
            }
          }
        },
        isGreaterThanOrEqualTo(query) {
          switch (indexName) {
            case "id": {
              return executeQuery(store, IDBKeyRange.lowerBound(query, false));
            }
            default: {
              return Promise.reject(
                new Error("Trying to run query on unknown index: " + indexName)
              );
            }
          }
        },
        isLessThan(query) {
          switch (indexName) {
            case "id": {
              return executeQuery(store, IDBKeyRange.upperBound(query, true));
            }
            default: {
              return Promise.reject(
                new Error("Trying to run query on unknown index: " + indexName)
              );
            }
          }
        },
        isLessThanOrEqualTo(query) {
          switch (indexName) {
            case "id": {
              return executeQuery(store, IDBKeyRange.upperBound(query, false));
            }
            default: {
              return Promise.reject(
                new Error("Trying to run query on unknown index: " + indexName)
              );
            }
          }
        },
        isBetween(query) {
          switch (indexName) {
            case "id": {
              return executeQuery(
                store,
                IDBKeyRange.bound(query.from, query.to, false, false)
              );
            }
            default: {
              return Promise.reject(
                new Error("Trying to run query on unknown index: " + indexName)
              );
            }
          }
        },
        isEqualTo(query) {
          switch (indexName) {
            case "id": {
              return executeQuery(store, IDBKeyRange.only(query));
            }
            default: {
              return Promise.reject(
                new Error("Trying to run query on unknown index: " + indexName)
              );
            }
          }
        },
      };
    }
    sortBy(
      indexName: BookmarksIndexes,
      options: {
        transaction?: IDBTransaction;
        withJoins: false;
        count?: number;
      }
    ): Promise<ReadonlyArray<Bookmark>> {
      return new Promise((resolve, reject) => {
        const tx =
          options?.transaction ?? db.transaction(["Bookmarks"], "readonly");
        const store = tx.objectStore("Bookmarks");
        let DBGetRequest: IDBRequest | null = null;
        switch (indexName) {
          case "id": {
            DBGetRequest = store.getAll(undefined, options?.count);
            break;
          }
          default: {
            return Promise.reject(
              new Error("Trying to run query on unknown index: " + indexName)
            );
          }
        }
        if (DBGetRequest != null) {
          DBGetRequest.onerror = () => {
            if (DBGetRequest != null) {
              reject(DBGetRequest.error);
            } else {
              reject(
                new Error("Unknown error occurred trying to perform operation")
              );
            }
          };
          DBGetRequest.onsuccess = () => {
            if (DBGetRequest != null && DBGetRequest.result != null) {
              resolve((DBGetRequest as IDBRequest).result);
            } else {
              reject(new Error("No result found for query"));
            }
          };
        } else {
          reject(new Error("No available index for given query"));
        }
      });
    }
  }
  const bookmarksClient = new BookmarksClientImpl();
  return {
    transaction: (
      storeNames: Array<"Bookmarks">,
      mode?: IDBTransactionMode
    ): IDBTransaction => {
      return db.transaction(storeNames, mode);
    },
    bookmarks: bookmarksClient,
  };
}
export function init(): Promise<BookmarksDatabaseClient> {
  return new Promise((resolve, reject) => {
    function createObjectStore(
      db: IDBDatabase,
      storeName: string,
      options: IDBObjectStoreParameters
    ): IDBObjectStore {
      if (DBOpenRequest.transaction == null) {
        throw new Error(
          "Error opening database. Open request transaction is null."
        );
      }
      if (db.objectStoreNames.contains(storeName)) {
        return DBOpenRequest.transaction.objectStore(storeName);
      } else {
        return db.createObjectStore(storeName, options);
      }
    }
    function removeUnusedIndexes(
      store: IDBObjectStore,
      indexNames: ReadonlyArray<string>
    ): void {
      for (const indexName of Array.from(store.indexNames)) {
        if (indexNames.includes(indexName) === false) {
          store.deleteIndex(indexName);
        }
      }
    }
    const DBOpenRequest = globalThis.indexedDB.open("BookmarksDatabase", 3);
    DBOpenRequest.onerror = () => {
      reject(new Error("Error opening database: BookmarksDatabase"));
    };
    DBOpenRequest.onsuccess = () => {
      const db = DBOpenRequest.result;
      resolve(createDatabaseClient(db));
    };
    DBOpenRequest.onupgradeneeded = () => {
      const db = DBOpenRequest.result;
      const BookmarksStore = createObjectStore(db, "Bookmarks", {
        keyPath: "id",
      });
      removeUnusedIndexes(BookmarksStore, []);
    };
  });
}
