export function isValidUrl(url: string) {
  const regex =
    /^(https?:\/\/)?([a-zA-Z0-9_-]+(:[a-zA-Z0-9_-]+)?@)?((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|localhost|([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]{2,})+))(:(\d{1,5}))?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/;
  return regex.test(url);
}

export function ensureHttps(url: string): string {
  // Check if the URL already starts with 'http://' or 'https://'
  if (!/^https?:\/\//i.test(url)) {
    // If not, prepend 'https://' to the URL
    return "https://" + url;
  }

  // If the URL starts with 'http://', replace it with 'https://'
  return url.replace(/^http:\/\//i, "https://");
}
