import { querySelector } from "../helper/query-helper";

export class LinkForm {
  form: HTMLFormElement;
  input: HTMLInputElement;
  button: HTMLButtonElement;

  constructor() {
    this.form = querySelector<HTMLFormElement>(document, "#link-form");
    this.input = querySelector<HTMLInputElement>(this.form, "#link-input");
    this.button = querySelector<HTMLButtonElement>(this.form, "button");
  }

  onSubmit(fn: (val: string) => void): void {
    this.form.addEventListener("submit", (e) => {
      e.preventDefault();
      const input = this.input.value.trim();
      if (input === "") {
        return;
      }

      fn(input);
    });
  }

  clearInput(): void {
    this.input.value = "";
  }

  addErrorMessage(string: string): void {
    const heading = querySelector<HTMLElement>(
      document,
      "#link-form-error-msg"
    );

    heading.textContent = string;
  }
}
