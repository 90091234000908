const axios = require("axios/dist/browser/axios.cjs"); // browser

const PROXY_CORS = "https://mysterious-bayou-34942-3f710946381f.herokuapp.com";

interface Metadata {
  title: string;
  image: string | undefined;
  url: string;
}

/**
 * Checks if a URL exists and fetches its metadata.
 * @param url The URL to check and fetch metadata from.
 * @returns A promise that resolves to a Metadata object if URL is accessible, otherwise an error.
 */
export async function fetchMetadata(
  url: string
): Promise<{ metadata?: Metadata; error?: string }> {
  try {
    const response = await axios.get(`${PROXY_CORS}/${url}`, { timeout: 2000 });
    const data = response;
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.data, "text/html");

    const metadata: Metadata = {
      title:
        doc.querySelector('meta[name="title"]')?.getAttribute("content") ||
        doc.querySelector("title")?.text ||
        "url",
      image:
        doc
          .querySelector('meta[property="og:image"]')
          ?.getAttribute("content") || "",
      url:
        doc.querySelector('meta[property="og:url"]')?.getAttribute("content") ||
        url,
    };

    return { metadata: metadata };
  } catch (error) {
    return { error: "URL not accessible or does not exist." };
  }
}
