import { Bookmark } from "../codegen/bookmarks";
import {
  clearChildren,
  closest,
  onSelector,
  querySelector,
} from "../helper/query-helper";

export class BookmarkList {
  template: HTMLTemplateElement;
  bookmarkList: HTMLElement;

  constructor() {
    this.template = querySelector<HTMLTemplateElement>(
      document,
      "#bookmark-item-template"
    );
    this.bookmarkList = querySelector<HTMLElement>(document, "#bookmark-list");
  }

  onDeleteItem(fn: (bookmarkId: string) => void): void {
    this.handleEvent(".bookmark-list-item .delete-button", "click", fn);
  }

  onEditItem(fn: (bookmarkId: string) => void): void {
    this.handleEvent(".bookmark-list-item .edit-button", "click", fn);
  }

  onSaveEditItem(
    fn: (newEditBookmark: {
      bookmarkId: string;
      title: string;
      url: string;
      linkInput: HTMLInputElement;
      saveButton: HTMLButtonElement;
    }) => void
  ): void {
    onSelector(".bookmark-list-item", "submit", (e) => {
      e.preventDefault();
      if (e.target instanceof Element) {
        const parent = closest<HTMLElement>(e.target, ".bookmark-list-item");
        const bookmarkId = parent.dataset.id;

        const titleInput = querySelector<HTMLInputElement>(
          parent,
          `#title-input-${bookmarkId}`
        ).value.trim();

        const linkInput = querySelector<HTMLInputElement>(
          parent,
          `#url-input-${bookmarkId}`
        );

        const linkValue = linkInput.value.trim();

        const saveButton = querySelector<HTMLButtonElement>(
          parent,
          ".save-button"
        );

        if (
          titleInput === "" ||
          linkValue === "" ||
          typeof bookmarkId === "undefined"
        ) {
          return;
        }

        fn({
          bookmarkId,
          title: titleInput,
          url: linkValue,
          linkInput: linkInput,
          saveButton: saveButton,
        });
      }
    });
  }

  openEditInput(bookmark: Bookmark): void {
    const itemToMark = querySelector(
      this.bookmarkList,
      `#bookmark-item-${bookmark.id}`
    );
    const editSection = querySelector<HTMLElement>(
      itemToMark,
      ".bookmark-list-edit-input"
    );

    if (editSection.classList.contains("hidden")) {
      editSection.classList.remove("hidden");
    } else {
      editSection.classList.add("hidden");
    }

    const titleInput = querySelector<HTMLInputElement>(
      itemToMark,
      ".title-input"
    );
    const linkInput = querySelector<HTMLInputElement>(itemToMark, ".url-input");

    linkInput.value = bookmark.url;
    titleInput.value = bookmark.title;

    titleInput.setAttribute("id", `title-input-${bookmark.id}`);
    linkInput.setAttribute("id", `url-input-${bookmark.id}`);
    editSection.setAttribute("id", `bookmark-list-edit-input-${bookmark.id}`);
  }

  removeBookmark(bookmarkId: string): void {
    const itemToRemove = querySelector(
      this.bookmarkList,
      `#bookmark-item-${bookmarkId}`
    );
    itemToRemove.remove();
  }

  addBookmark(bookmark: Bookmark): void {
    const newBookmark = this.createNewBookmarkItem(bookmark);
    this.bookmarkList.append(newBookmark);
  }

  clear(): void {
    clearChildren(this.bookmarkList);
  }

  handleEvent<EventName extends keyof GlobalEventHandlersEventMap>(
    selector: string,
    eventName: EventName,
    handler: (id: string) => void
  ): void {
    onSelector(selector, eventName, (e) => {
      if (e.target instanceof Element) {
        const parent = closest<HTMLElement>(e.target, ".bookmark-list-item");
        const bookmarkId = parent.dataset.id;
        if (bookmarkId != null) {
          handler(bookmarkId);
        }
      }
    });
  }

  createNewBookmarkItem(bookmark: Bookmark): HTMLElement {
    const clone = this.template.content.cloneNode(true) as HTMLElement;
    const heading = querySelector<HTMLElement>(clone, "h3");
    const listItem = querySelector<HTMLElement>(clone, "li");
    const link = querySelector<HTMLAnchorElement>(clone, "a");
    const imgHolder = querySelector<HTMLElement>(clone, "#bookmark-item-img");
    const createDate = querySelector<HTMLElement>(clone, "#bookmark-item-date");

    if (bookmark.img !== "") {
      const img = document.createElement("img");
      img.src = bookmark.img;
      imgHolder.appendChild(img);
    }

    heading.textContent = bookmark.title;
    link.href = bookmark.url;
    link.textContent = bookmark.url;
    createDate.textContent = `Created: ${bookmark.createdAt.toLocaleString(
      "default",
      { day: "2-digit", month: "long", year: "2-digit" }
    )}`;

    listItem.dataset.id = `${bookmark.id}`;
    listItem.setAttribute("id", `bookmark-item-${bookmark.id}`);
    return clone;
  }
}
