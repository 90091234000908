import { Controller } from "./controller";
import { init } from "./codegen/bookmarks";
import { LinkForm } from "./views/link-form";
import { BookmarkList } from "./views/bookmark-list";
import { ITEMS_PER_PAGE, renderPagination } from "./views/pagination";
import { ResultPage } from "./views/result-page";

async function main() {
  const db = await init();
  const linkForm = new LinkForm();
  const bookmarkList = new BookmarkList();
  const resultPage = new ResultPage();
  const controller = new Controller(db, bookmarkList, linkForm, resultPage);

  (window as any).changePage = (page: number) => {
    const totalPages = Math.ceil(controller.totalItems / ITEMS_PER_PAGE);
    if (page < 1 || page > totalPages) return;
    controller.currentPage = page;
    controller.show(page);
  };

  (window as any).nextPage = () => {
    const totalPages = Math.ceil(controller.totalItems / ITEMS_PER_PAGE);
    if (controller.currentPage + 1 > totalPages) return;

    controller.currentPage += 1;
    controller.show(controller.currentPage);
  };

  (window as any).prevPage = () => {
    const totalPages = Math.ceil(controller.totalItems / ITEMS_PER_PAGE);
    if (controller.currentPage <= 1) return;

    controller.currentPage -= 1;
    controller.show(controller.currentPage);
  };

  await controller.show(1);
}

main();
